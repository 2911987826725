import React, { useState } from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"

import { Layout } from "../../components/Layout"
import Pagination from "../../components/Pagination"
import SEO from "../../components/SEO"
import leaf from "../../images/leaflet2024.pdf"
import { AiOutlineCloudDownload } from "react-icons/ai"
import { IconContext } from "react-icons"
import { useLocationData } from "../../hooks/locationHook"

const DownMark = () => (
  <IconContext.Provider
    value={{ color: "white", size: "30px", marginRight: "15px" }}
  >
    <AiOutlineCloudDownload />
  </IconContext.Provider>
)

const StyledPDF = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 5vh 0;
  ${() =>
    media.sp(css`
      margin: 0 16px;
    `)}
  a.download {
    margin: 30px 0 30px;
    padding: 10px 0px;
    width: 220px;
    background: black;
    border-radius: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.5s;
    &:hover {
      background: gray;
    }
  }
  .react-pdf__Page__canvas {
    border: 1px solid #dadada;
  }
`

const Leaflet = ({ location }) => {
  const screenWidth = typeof window !== "undefined" ? window.innerWidth : 0
  const screenMarginPercent = 0.05

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  // const [hasPage, setHasPage] = useState(false)
  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }
  useLocationData(location.pathname)

  return (
    <Layout>
      <SEO>
        <title>会社パンフレット</title>
        <meta
          name="description"
          content="株式会社フランソアの会社パンフレットです。"
        />
      </SEO>
      {/* <StyledPdf>
        <object data={leaf} type="application/pdf" width="100%" height="100%">
          <iframe src={leaf} width="100%" height="100%">
            <p>
              <b>表示されない時はこちら</b>:{" "}
              <a href={leaf}>PDF をダウンロード</a>.
            </p>
          </iframe>
        </object>
      </StyledPdf> */}
      <StyledPDF>
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
        />
        <a className="download" href={leaf} download>
          <DownMark />
          PDF をダウンロード
        </a>
        {/* Display leaflet pdf file */}
        {typeof window !== "undefined" && (
          <Document file={leaf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              width={screenWidth - screenWidth * screenMarginPercent}
              scale={screenWidth <= 500 ? 0.9 : 0.6}
            />
          </Document>
        )}
      </StyledPDF>
    </Layout>
  )
}

export default Leaflet
